.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.question-container {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.option {
  cursor: pointer;
  margin-right: 10px;
}

/* Remove the default blue circle for radio buttons */
.option input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 2px solid #555;
  border-radius: 4px; /* Adjusted border-radius */
  width: 16px;
  height: 16px;
  margin: 0;
  outline: none;
}

/* Style the custom radio button */
.option input[type="radio"]:checked {
  background-color: #4caf50;
  border-color: #4caf50;
  color: #fff;
}
